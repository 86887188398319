import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import Experience from './Experience';
import ScrollAppear from './ScrollAppear';
import Masonry from '@mui/lab/Masonry';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import SvgLogo2 from './SvgLogo2';
const works = [
  {
    "name": "Ubaq",
    "logo": "643ffbb7f38462d57f680b9c_Logo_UBAQ_1.2.png",
    "position": "Tech Lead",
    "startDate": "Novembre 2020",
    "endDate": "Août 2022",
    "summary": "CLARDIAN est une MedTech qui développe des logiciels réglementaires pour les laboratoires pharmaceutiques. J'ai mis en œuvre la transition agile de l'entreprise, reconstruit l'équipe de développement et appliqué des méthodes de travail efficaces. 70% de management, 30% de développement back-end.",
    "highlights": [
      "Stratégie d'entreprise",
      "Recrutement/encadrement (10+ personnes)",
      "Méthode/environnement de travail (Scrum/Workflow/DevSecOps)",
      "Définition/suivi de KPI",
      "Coordination de projet",
      "Conception/Application de processus qualité",
      "Joomla / PHP / Javascript / VMWare / Symfony / QlikView / MySQL"
    ]
  },
  {
    "name": "Freelance",
    "logoSvg": <SvgLogo2 />,
    "position": "Project Manager",
    "startDate": "Avril 2020",
    "endDate": "Novembre 2020",
    "summary": "Après avoir composé un Scrum Squad, nous avons livré une solution 'Infomed' répondant aux besoins des laboratoires pharmaceutiques français en matière de pharmacovigilance. Mission répartie à 50% sur le développement et 50% sur la gestion technique / de projet.",
    "highlights": [
      "Développement back-end",
      "Planification, budgétisation",
      "Direction, management et motivation de l'équipe projet et technique",
      "Rédaction de tickets, stories",
      "Rétro-ingénierie",
      "Joomla / PHP / Javascript / MySQL"

    ]
  },
  {
    "name": "EQUATIV",
    "logo": "logo.svg",
    "position": "Technical Account Manager",
    "startDate": "Septembre 2018",
    "endDate": "Janvier 2020",
    "summary": "En tant qu'Account Manager au sein d'une entreprise spécialisée dans le domaine du marketing digital, mon rôle était d'occuper la position d'interlocuteur technique privilégié du client.",
    "highlights": [
      "Gestion de projets",
      "Suivi des comptes client (Deezer, Boursorama, Gameloft…)",
      "Coordination",
    ]
  },
  {
    "name": "CABESTAN",
    "logo": "logo_blanc_et_rose.png",
    "position": "Développeur Web",
    "startDate": "Décembre 2016",
    "endDate": "Septembre 2018",
    "summary": "CABESTAN est une plateforme de gestion de campagnes marketing multicanal. J'ai développé un méta-langage pour l'interface emailing, corrigé le service d'envoi de SMS, amélioré les performances du front-end et l'UX. Avec mon équipe, nous avons conçu et développé la nouvelle solution basée sur Symfony et NodeJS, architecturée en micro-services.",
    "highlights": [
      "Développement de fonctionnalités",
      "Résolution d'incidents",
      "Rédaction de documentation",
      "Rétro-engineering",
      "ColdFusion / Java / Javascript / Symfony / NodeJS / REST / SOAP"
    ]
  },
  {
    "name": "Freelance",
    "logoSvg": <SvgLogo2 />,
    "position": "Développeur Web",
    "startDate": "2016",
    "endDate": "2018",
    "summary": "Delivered Joomla CMS training, created web training materials, and developed websites using Joomla and Symfony2.8 technologies."
  },
  {
    "name": "BMI SYSTEM",
    "logo": "clardian_logotype_rvb_bleu.jpg",
    "position": "Analyste programmeur",
    "startDate": "Décembre 2012",
    "endDate": "Janvier 2015",
    "summary": "BMI SYSTEM édite une application web basée sur un framework propriétaire. J'ai synchronisé les données clients avec notre solution via le développement de connecteurs de données et apporté des évolutions produit. Je suis ensuite allé au Vietnam pour être référent technique pour une durée de 6mois.",
    "highlights": [
      "Référent technique",
      "Développement de fonctionnalités",
      "Architecture technique",
      "PHP / Javascript / MySQL / XML"
    ]
  
  },
  {
    "name": "Générale de téléphone",
    "logo": "orange.png",
    "position": "Développeur web junior",
    "startDate": "Avril 2011",
    "endDate": "Septembre 2011",
    "summary": "Developed a new application for managing hotline services as part of a final year internship, using FullStack development from conception to final product."
  }
];

export default function Experiences(props) {
  const theme = useTheme();

  return (
    <Masonry  columns={1} spacing={2}>
        {works.map((work, index) => (
              <Box className="accordion">
                <Accordion >
                  <AccordionSummary className="accordion-summary" expandIcon={"•••"} >
                    <Typography >
                      {work.logo ? (
                          <img
                            style={{ maxWidth: '100%', height: '50px' }}
                            className="logo-entreprise"
                            src={`${process.env.PUBLIC_URL}/static/logo_entreprises/${work.logo}`}
                            alt="Description"
                          />
                        ) : work.logoSvg ? (
                           work.logoSvg
                        ) : null}
                    </Typography>
                  </AccordionSummary>
                  <Experience workdetails={work} />
                </Accordion>
              </Box>
        ))}
      </Masonry>
  );
}