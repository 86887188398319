import * as React from 'react';
import Box from '@mui/material/Box';
import { Chip, Grid, Typography } from '@mui/material';


export default function Experience(props) {

  return (
    <Box className="experience">
        <Grid container rowSpacing={5}>

            <Grid item xs={12}>
                <Box className="wd-title">
                    {props.workdetails.position}
                </Box>
            </Grid>

            <Grid  item xs={12}>
                <Box className="wd-dates">
                    📅 {props.workdetails.startDate} - {props.workdetails.endDate}
                </Box>
            </Grid>
            
            <Grid item xs={12}>
                <Box className="wd-summary">
                    {props.workdetails.summary}
                </Box>
            </Grid>

            
            <Box className="wd-highlights">
                {props.workdetails.highlights && props.workdetails.highlights.length > 0 ? (
                    props.workdetails.highlights.map((highlight, index) => (
                    <Chip key={index} label={highlight} variant='outlined'/>
                    ))
                ) : (
                    null
                    // Vous pouvez également choisir de ne rien afficher en mettant simplement 'null' ici
                )}
            </Box>
        </Grid>
    </Box>
  );
}