import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      const isAuthenticated = await checkTokenValidity();
      setAuth(isAuthenticated);
      setToken(Cookies.get('token'));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
      setLoading(false);
    };

    checkToken();
  }, []);

  const checkTokenValidity = async () => {
    const token = Cookies.get('token');
    if (token) {
      const cookieExpiresTimestamp = Cookies.get('token_expires_timestamp');
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (cookieExpiresTimestamp && parseInt(cookieExpiresTimestamp, 10) > currentTimestamp) {
        if (parseInt(cookieExpiresTimestamp, 10) - currentTimestamp < 24 * 3600) {
          await refreshAccessToken();
        }
        return true;
      }
    }
    return false;
  };

  const refreshAccessToken = async () => {
    try {
      // Perform refresh request here
      // Update token in cookies
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  async function LoginUser(credentials) {
    return fetch('/api/login_check',{
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json())
  }

  function LogUser(token){
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 48 * 60 * 60 * 1000);
    Cookies.set('token_expires_timestamp', Math.floor(expirationDate / 1000));
    Cookies.set('token', token, { expires: expirationDate });
  
    setAuth(true);
    setToken(token); // Add this line to set the token
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return (
    <AuthContext.Provider value={{ auth, setAuth, loading, LoginUser, LogUser, token }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};