// src/components/FolderStructure.js
import { Avatar, Box, Button, Chip, Container, Grow, IconButton, Slide, Zoom } from "@mui/material";
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';

// Define the folder structure manually
const folderStructure = {
  subFolders: [
    {
      previousFolder:"orange",
      folderName: "busclub",
      folderLabel: "Bus Club", 
      folderDate: "2023",
      folderLink: "https://www.aires-autoroute-areas.com/contenu/pass-busclub/",
      folderDescription: [
        {"Context" : "Areas, restaurateur du monde du voyage, propose, avec son réseau Busclub, plus de soixante aires sur les autoroutes de l’hexagone."},
        {"Type" : " Application mobile multiplateforme"},
        {"Temps" : "2 mois"},
        {"Equipe" : "1 développeur, 1 chef de projet"},
      ],
      folderIcons: [
        {
          name: "Apache",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        
        {
          name: "Android Studio",
       
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/androidstudio/androidstudio-original-wordmark.svg",
        
      },
        {
          name: "Axios",
        
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/axios/axios-plain-wordmark.svg",
      },
          
        {
          name: "Bitbucket",
        
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bitbucket/bitbucket-original-wordmark.svg",
      },
          
        {
          name: "Symfony",
        
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/symfony/symfony-original-wordmark.svg",
      },
      
      {
        name: "React",
      
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg",
      },
      
          
      ],
      folderClient:<img height='auto' width='20%' src='/static/logo_entreprises/014france.png' />,
      folderSubClient:<img height='auto' width='20%' src='/static/projets/company_logo/logo_areas_negative.png' />, 
      images: ["1.png"],
      nextFolder: "clubgagnant",
    },
    {
      previousFolder:"busclub",
      folderName: "clubgagnant",
      folderLabel: "Club gagnant", 
      folderDescription: [
        {"Context" : "Areas, restaurateur du monde du voyage, propose, avec son réseau Busclub, plus de soixante aires sur les autoroutes de l’hexagone."},
        {"Type" : " Application mobile multiplateforme"},
        {"Temps" : "2 mois"},
        {"Equipe" : "1 développeur, 1 chef de projet"},
      ],
      folderIcons: [
        {
          name: "EasyAdmin",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Symfony",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Plesk",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
      ],
      folderClient:<img height='auto' width='20%' src='/static/logo_entreprises/014france.png' />,
      folderSubClient:<img height='auto' width='20%' src='/static/projets/company_logo/coca.png' />, 
      folderDate: "2024",
      folderLink: "https://club-gagnants.com/",
      images: ["1.png", "2.png"],
      nextFolder: "COTEG",
    },
    {
      previousFolder:"clubgagnant",
      folderName: "COTEG",     
      folderLabel: "COTEG", 
      folderDescription: [
        {"Context" : "Areas, restaurateur du monde du voyage, propose, avec son réseau Busclub, plus de soixante aires sur les autoroutes de l’hexagone."},
        {"Type" : " Application mobile multiplateforme"},
        {"Temps" : "2 mois"},
        {"Equipe" : "1 développeur, 1 chef de projet"},
      ],
      folderIcons: [
        {
          name: "EasyAdmin",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Symfony",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Plesk",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
      ],
      folderDate: "2024",
      folderClient:<img height='auto' width='20%' src='/static/projets/company_logo/catherine.png' />,
      folderSubClient:<img height='auto' width='20%' src='/static/projets/company_logo/coteg.png' />, 
      folderLink: "https://www.coteg-sa.fr/",
      images: ["1.png", "2.png", "3.png"],
      nextFolder: "grimpezausommet",
    },
    {
      previousFolder:"COTEG",
      folderName: "grimpezausommet",
      folderLabel: "Grimpez au sommet",
      folderDescription: [
        {"Context" : "Areas, restaurateur du monde du voyage, propose, avec son réseau Busclub, plus de soixante aires sur les autoroutes de l’hexagone."},
        {"Type" : " Application mobile multiplateforme"},
        {"Temps" : "2 mois"},
        {"Equipe" : "1 développeur, 1 chef de projet"},
      ],
      folderIcons: [
        {
          name: "EasyAdmin",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Symfony",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Plesk",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
      ],
      folderDate: "2024",
      folderClient:<img height='auto' width='20%' src='/static/logo_entreprises/014france.png' />,
      folderSubClient:<img height='auto' width='20%' src='/static/projets/company_logo/carrefour.png' />, 
      folderLink:"https://grimpezausommet.com/",
      images: ["1.png", "2.png", "3.png"],
      nextFolder: "illicado",
    },
    {
      previousFolder:"grimpezausommet",
      folderName: "illicado",
      folderLabel: "Illicado",
      folderDescription: [
        {"Context" : "Areas, restaurateur du monde du voyage, propose, avec son réseau Busclub, plus de soixante aires sur les autoroutes de l’hexagone."},
        {"Type" : " Application mobile multiplateforme"},
        {"Temps" : "2 mois"},
        {"Equipe" : "1 développeur, 1 chef de projet"},
      ],
      folderIcons: [
        {
          name: "EasyAdmin",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Symfony",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Plesk",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
      ],
      folderDate: "2024",
      folderClient:<img height='auto' width='20%' src='/static/logo_entreprises/014france.png' />,
      folderSubClient:<img height='auto' width='20%' src='/static/projets/company_logo/illicado.png' />, 
      folderLink: "https://grand-jeu-illicado.com/",
      images: ["1.png", "2.png"],
      nextFolder: "myeasygame",
    },
    {
      previousFolder:"illicado",
      folderName: "myeasygame",     
      folderLabel: "My easy game", 
      folderDescription: [
        {"Context" : "Areas, restaurateur du monde du voyage, propose, avec son réseau Busclub, plus de soixante aires sur les autoroutes de l’hexagone."},
        {"Type" : " Application mobile multiplateforme"},
        {"Temps" : "2 mois"},
        {"Equipe" : "1 développeur, 1 chef de projet"},
      ],
      folderIcons: [
        {
          name: "EasyAdmin",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Symfony",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Plesk",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
      ],
      folderDate: "2024",
      folderClient:<img height='auto' width='20%' src='/static/logo_entreprises/014france.png' />,
      folderSubClient:<img height='auto' width='20%' src='/static/projets/company_logo/carrefour.png' />, 
      folderLink: "https://www.coteg-sa.fr/",
      images: ["1.png", "2.png", "3.png"],
      nextFolder: "ONN",
    },
    {
      previousFolder:"myeasygame",
      folderName: "ONN",
      folderLabel: "Observatoire national du numérique", 
      folderDescription: [
        {"Context" : "Areas, restaurateur du monde du voyage, propose, avec son réseau Busclub, plus de soixante aires sur les autoroutes de l’hexagone."},
        {"Type" : " Application mobile multiplateforme"},
        {"Temps" : "2 mois"},
        {"Equipe" : "1 développeur, 1 chef de projet"},
      ],
      folderIcons: [
        {
          name: "EasyAdmin",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Symfony",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
        {
          name: "Plesk",
          link:"https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apache/apache-original-wordmark.svg"
        },
      ],
      folderDate: "2024",
      folderClient:<img height='auto' width='20%' src='/static/logo_entreprises/014france.png' />,
      folderSubClient:<img height='auto' width='20%' src='/static/projets/company_logo/illicado.png' />,
      folderLink: "https://onn.gouv.sn/senegal-numerique-sn202/",
      images: ["1.png", "2.png", "3.png"],
      nextFolder: "pirelli",
    },
    {
      previousFolder:"ONN",
      folderName: "pirelli",
      folderLabel: "Pirelli compétition",
      folderLink: "https://plateforme-gestion-foc.com/",
      images: ["1.png", "2.png"],
      nextFolder: "portfolio",
    },
    {
      previousFolder:"pirelli",
      folderName: "portfolio",
      folderLabel: "Un tour dans nos sacs",
      images: ["1.png", "2.png", "3.png"],
      nextFolder: "orange",
    },
    {
      previousFolder:"portfolio",
      folderName: "orange",
      folderLabel: "Orange store",
      images: ["1.JPG", "2.JPG", "3.JPG", "4.JPG"],
      nextFolder: "busclub",
      },
  ],
};

// Helper function to find a folder by name
const findFolderByName = (folderName, folderStructure) => {
  return folderStructure.subFolders.find(
    (folder) => folder.folderName === folderName
  );
};

// Folder component to display images and navigate between folders
const Folder = ({ folder, path, isVisibleFolder, onFolderClick }) => {
  const [visibleImageIndex, setVisibleImageIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState("left"); // Controls slide direction for images

  // Handle image dot click
  const handleImageDotClick = (index) => {
    setSlideDirection(index > visibleImageIndex ? "left" : "right");
    setVisibleImageIndex(index);
  };
  return (
    <Box className='projectsContainer' style={{backdropFilter:'inherit'}}>
      
      <IconButton className='prevButton' onClick={() => {setVisibleImageIndex(0); onFolderClick(folder.previousFolder)}}  disableRipple={true} size="large" color="primary" ><FontAwesomeIcon icon={faChevronLeft} /></IconButton>
      
      <IconButton className='nextButton' onClick={() => {setVisibleImageIndex(0); onFolderClick(folder.nextFolder)}} disableRipple={true} size="large" color="primary" ><FontAwesomeIcon icon={faChevronRight} /></IconButton> 
      <Zoom
        in={isVisibleFolder}
        style={{ transformOrigin: "0 0 0" }}
        timeout={500} // Animation duration
      >
        <div>
          <div className="projectHeader">
            <div>
              <h3>
                {folder.folderLabel} 
                {folder.folderLink && 
                  <a href={folder.folderLink} target="_blank" rel="noopener noreferrer" className="projectLink">
                    &nbsp; <FontAwesomeIcon  icon={faArrowUpRightFromSquare}  />
                  </a>
                }
              </h3>
            </div>
            <div>
            {folder.folderDate} 
            </div>
          </div>
          {isVisibleFolder && folder.images && (    
                <div className="imageDotsContainer">
                  {folder.images.length > 1 && folder.images.map((_, index) => (
                    <span
                      key={index}
                      onClick={() => handleImageDotClick(index)}
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                        margin: "2px",
                        borderRadius: "50%",
                        backgroundColor: index === visibleImageIndex ? "#5CF5CB" : "#fefefe",
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                      }}
                    ></span>
                  ))}
                </div>
          )}
          <div style={{display:'flex', flexDirection:'row'}}>
            {isVisibleFolder && folder.images && (    
                
                <div className="imageContainer">

                  <Zoom
                    in={true}
                    timeout={500} // Animation duration for image slide
                    key={visibleImageIndex} // Key to trigger the slide animation on image change
                  >
                    <img
                      className='image hover-effect'
                      src={`${path}/${folder.folderName}/${folder.images[visibleImageIndex]}`}
                      alt={folder.images[visibleImageIndex]}
                      width="100%"
                    />
                  </Zoom>

                </div>
            )}
            
            <div className="descriptionContainer">
              {folder.folderClient && 
                <div className="boxShadow" style={{borderTopRightRadius:'15px'}}>
                  Développé pour {folder.folderClient} {folder.folderSubClient && <>à destination de  {folder.folderSubClient}</>}
                </div>
              }
              <div className="boxShadow" style={{flex:'1',flexGrow:'1'}}>
              {folder.folderDescription && folder.folderDescription.map((item, index) => (
                <React.Fragment key={index}>
                  {Object.entries(item).map(([key, value]) => (
                    <p key={key}>
                      <span>{key}: </span>
                      <span>{value}</span>
                    </p>
                  ))}
                </React.Fragment>
              ))}
              </div>
              
              <div className="iconsContainer boxShadow">

                {folder.folderIcons && folder.folderIcons.map((item) => (
                  <Chip
                  label={item.name}
                  variant="outlined"
                  color="primary"
                />
                  
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Zoom>
      
    </Box>
  );
};

const Projects = () => {
  const basePath = "/static/projets"; // Assuming your images are inside 'public/images'
  const [currentFolder, setCurrentFolder] = useState("busclub");
  const [isFolderVisible, setIsFolderVisible] = useState(true); // Controls folder visibility

  // Find the current folder based on the folder name
  const currentFolderData = findFolderByName(currentFolder, folderStructure);

  const handleFolderClick = (nextFolderName) => {
    // Start exit animation by setting visibility to false
    setIsFolderVisible(false);

    // Delay switching the folder until the exit animation completes
    setTimeout(() => {
      setCurrentFolder(nextFolderName);
      setIsFolderVisible(true); // Show new folder after transition
    }, 200); // Delay should match the timeout duration of the Grow component
  };
  
  return (
    <div>
      {currentFolderData ? (
        <Folder
          folder={currentFolderData}
          path={basePath}
          isVisibleFolder={isFolderVisible}
          onFolderClick={handleFolderClick}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Projects;
