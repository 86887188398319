import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main:'#5CF5CB',
    }
  },
  components: {
    MuiGrid:{
      styleOverrides:{
        root:{
          color:"#000",
        }
      }
    },
    MuiBox:{
      styleOverrides:{
        root:{
          margin:"10px",
        }
      }
    },
    MuiInputBase:{
      styleOverrides:{
        input:{
          color:"#FFF",
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#F8E08E',
          '&.Mui-focused': {
            color: '#FF9200', // Remplacez ceci par la couleur souhaitée
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Style pour la bordure en état normal
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F8E08E', // Remplacez ceci par la couleur souhaitée pour la bordure normale
          },
          // Appliquer le style lorsque l'élément est focalisé
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF9200', // Remplacez ceci par la couleur souhaitée
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: { 
        root: { 
          display: 'inline',
          fontFamily: 'Raleway', 
          fontWeight: 'Bolder',
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
            backgroundColor: '#3C393A',
        },
        root:{
          width:'240px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // Cela s'appliquera à toutes les icônes SVG par défaut
          color: '#0F3538', // Couleur par défaut pour les icônes hors boutons
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#FAFAF0', // Couleur de fond pour les boutons
          color: '#0F3538', // Couleur du texte pour les boutons
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#FF6600', // Assombrir la couleur de fond au survol
          },
          // Cela s'appliquera aux icônes SVG à l'intérieur des boutons
          '& .MuiSvgIcon-root': {
            color: '#FFF', // Couleur des icônes dans les boutons
          },
        },
      },
    },
    MuiMasonry: {
      styleOverrides: {
        root: {
          margin: '0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // Your styles here
          borderRadius:'5px !important',
          border: '1px solid rgba(255, 255, 255, 0.02)',
          boxShadow: '0px 0px 50px #00000075 !important',
          backdropFilter: 'blur(8px)',
          '&:before': {
            display: 'none',
          },
          // Other styles...
        },
        expanded: {
          // Styles for when the Accordion is expanded
        },
        // You can also override other parts like MuiAccordionSummary, MuiAccordionDetails
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          
          border: '1px solid #5cf5cb14',
          // Your styles here,
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          // Other styles...
        },
        expanded: {
          color:'#abc',
          // Styles for when the Accordion is expanded
        },
        // You can also override other parts like MuiAccordionSummary, MuiAccordionDetails
      },
    },
    // ... autres composants et configurations du thème
  },
});

export default theme;