import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from './components/contexts/AuthProvider';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./theme/theme"; // Assurez-vous que ce fichier exporte bien un objet thème MUI

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
