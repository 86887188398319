import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Experiences from './Experiences';
import Projects from './Projects';
import { InlineWidget } from 'react-calendly';

const Window = ({ type, data, visibility, closeWindow, background, windowName, width, height, expandWindow, windowExpanded}) => {
  const [numPages, setNumPages] = useState<number>();
  const contentRef = useRef<HTMLDivElement>(null);
  const [userMessage, setUserMessage] = useState("");  
  const [message, setMessage] = useState([
    { prompt: '', output: '' },
  ]);
  const [messages, setMessages] = useState([]);
    const [isProcessing, setIsProcessing] = useState({}); // New state to track processing
    const [loading, setLoading] = useState(false); // New state to track processing

  const contentRefs = useRef([]); // Array to store references to each editablePrompt


  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


  type Message = {
    role: "assistant" | "user" | "system";
    content: string;
  }
  async function pushMessage(idx: number) {
    setLoading(true);
    console.log(messages[idx]);
    setIsProcessing(prevState => ({
      ...prevState,
      [idx]: true,
    }));
  
    const newEntry = { prompt: userMessage, output: "" };
  
    // First, update the messages state with the new user message
    setMessage(prevMessages => [...prevMessages, newEntry]); // Adding new user message to the state
    setMessages(prev => [...prev, { role: "user", content: userMessage }]); // Same for the messages used in the request
  
    // Prepare the body for the request with the updated messages
    const body = {
      model: "benjaminguendouz",
      messages: [...messages, { role: "user", content: userMessage }] // Make sure we send the updated messages
    };
  
    let hasProcessed = false;
    let responseStream = "";
    let buffer = ""; // Accumulate data as it comes in
  
    try {
      console.log("Starting fetch...");
      const response = await fetch("https://benjaminguendouz.fr/api/chat", {
        method: "POST",
        body: JSON.stringify(body),
      });
  
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("Failed to read response body");
      }
      const decoder = new TextDecoder();
  
      setLoading(false);
      while (true) {
        const { value, done } = await reader.read();
        buffer += decoder.decode(value, { stream: true });
  
        while (true) {
          const openBraceIndex = buffer.indexOf("{");
          const closeBraceIndex = buffer.indexOf("}");
  
          if (openBraceIndex !== -1 && closeBraceIndex !== -1) {
            let nestedLevel = 0;
            let start = openBraceIndex;
            let end = openBraceIndex;
  
            for (let i = start; i < buffer.length; i++) {
              if (buffer[i] === '{') nestedLevel++;
              if (buffer[i] === '}') nestedLevel--;
  
              if (nestedLevel === 0) {
                end = i;
                break;
              }
            }
  
            if (nestedLevel === 0) {
              const jsonStr = buffer.slice(start, end + 1);
  
              try {
                const json = JSON.parse(jsonStr);
                if (json.done === false) {
                  // Split incoming content into words
                  const words = json.message.content.split(' ');
  
                  // Append each word one by one with a delay
                  for (let word of words) {
                    responseStream += word === '' ? word + ' ' : word; // Add spaces between words
                    newEntry.output = `🤖 ${formatText(responseStream)}`;
  
                    // Update message history to display word by word
                    setMessage(prevMessages => {
                      const updatedMessages = [...prevMessages];
                      updatedMessages[updatedMessages.length - 1] = { ...newEntry };
                      return updatedMessages;
                    });
  
                    // Introduce a slight delay between each word (e.g., 50ms)
                    await new Promise(resolve => setTimeout(resolve, 50));
                  }
                }
  
                buffer = buffer.slice(end + 1);
                hasProcessed = true;
              } catch (error) {
                break;
              }
            } else {
              break;
            }
          } else {
            break;
          }
        }
  
        if (done && hasProcessed) break;
      }
  
      if (responseStream !== '') {
        // When responseStream is not empty, update the message with the assistant's final response
        setMessage(prevMessages => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            prompt: userMessage,
            output: `🤖 ${formatText(responseStream)}`,
          };
          return updatedMessages;
        });
  
        setMessages(prev => [...prev, { role: "assistant", content: responseStream }]);
      }
    } catch (error) {
      console.error("Error streaming response:", error);
      newEntry.output = "> Error retrieving AI response.";
      setMessage(prevMessages => [...prevMessages.slice(0, -1), newEntry]); // Show error message after failure
    }
  
    // Reset user message, processing state, and loading state after response is received
    setUserMessage("");
    setIsProcessing(prevState => ({
      ...prevState,
      [idx]: false,
    }));
  
    // Set focus to the last editable div
    setTimeout(() => {
      const lastEditable = contentRefs.current[contentRefs.current.length - 1];
      lastEditable?.focus(); // Focus on the latest editable prompt
    }, 0);
  
    setLoading(false);
  }
  

function setChatFocus()  {
    // Set focus to the last editable div
    setTimeout(() => {
      const lastEditable = contentRefs.current[contentRefs.current.length - 1];
      lastEditable?.focus(); // Focus on the latest editablePrompt
    }, 0);
}

// Helper function to format special characters
function formatText(text) {
  // Replace special characters with visible equivalents (HTML entities, newlines, etc.)
  return text
    .replace(/\n/g, "<br />")  // Convert newline characters to <br />
    .replace(/\r/g, "")        // Remove carriage return characters
    .replace(/\\n/g, "<br />") // Handle escaped \n
    .replace(/\\r/g, "")       // Handle escaped \r
    .replace(/\\t/g, "&emsp;") // Handle tabs (optional, you can replace with something else)
    .replace(/\\'/g, "&#39;")  // Handle escaped single quotes
    .replace(/\\"/g, "&quot;") // Handle escaped double quotes
    .replace(/&/g, "&amp;")    // Handle the ampersand symbol
    .replace(/</g, "&lt;")     // Handle less than symbol
    .replace(/>/g, "&gt;");    // Handle greater than symbol
}
const loader = '<div class="loader loader--style8" title="7"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve"><rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2"><animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" /><animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" /><animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" /></rect><rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2"><animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" /><animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" /><animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" /></rect><rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2"><animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" /><animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" /><animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" /></rect></svg></div>';
  function isCalendlyEvent(e) {
    return e.data.event &&
           e.data.event.indexOf('calendly') === 0;
  };
  function decodeHtml(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.documentElement.textContent || "";
  }
  useEffect(() => {
    contentRef.current?.addEventListener('touchstart', function() {
      setChatFocus();
    });
    // Listen for Calendly event
    if(type==='contact'){
      window.addEventListener("message", function(e) {
        if (isCalendlyEvent(e)) {
          if (e.data.event === "calendly.page_height") {
            const newHeight = e.data.payload.height;
            if (contentRef.current) {
              contentRef.current.style.height = newHeight;
            }
          }
        }
      });
    }


  }, []);


  // Function to handle Enter key press inside contentEditable div
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents adding a new line
      const idx = message.length; // Use the index of the message being added
      pushMessage(idx);
    }
  };
  const renderContent = () => {
    switch (type) {
      case 'pdf':
        return (
          <Document
            file={`${process.env.PUBLIC_URL}/static/cv.pdf`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                scale={3}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        );
      case 'exp':
        return <Experiences />;
      case 'video':
        return <div>
              <video
                loop
                autoPlay
                controls
                style={{ maxWidth: '100%', height: 'auto', filter:'none' }}
                onError={(e) => console.error('Error loading video:', e)}
              >
                <source src="/static/interview.mp4" type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
      case 'contact':
        return <InlineWidget url="https://calendly.com/benjamin-guendouz" />;
      case 'console':
        return (
          <>
          {loading && (<div className='chatLoader' dangerouslySetInnerHTML={{ __html: loader }} />)}
          <span className="scroll-start-at-top"></span>
          <div style={{ fontFamily: '"Nerd Font", sans-serif', padding: "15px" }}>
            
            {message.map((item, index) => (
              <React.Fragment key={index}>
                {item.output && (
                  <>
                  <span>
                  {/* Decode HTML entities and use dangerouslySetInnerHTML */}
                  <div style={{marginLeft: '5%'}}
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(item.output),  // Decode and render HTML
                    }}
                  />
                  </span>
                  <br />
                  </>
                )}
                {!isProcessing[index] &&  (
                  <>
                  <span className="prompt">Ben@ONLINE-GZDV2023</span>:<span className="prompt_message">~$/home$</span>
                    <div
                      id="editablePrompt"
                      ref={el => {
                        contentRefs.current[index] = el; // Store ref for each prompt
                      }}
                      contentEditable={index === message.length-1}
                      onInput={(e) => setUserMessage(e.currentTarget.innerText)}
                      onKeyDown={handleKeyDown}
                      style={{
                        display: "inline-block",
                        padding: "5px",
                        minHeight: "30px",
                        width: "100%",
                        marginBottom: "10px"
                      }}>
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
            
          </div>
          </>
        );
      case 'projects':
        return <Projects />;
        
      case 'linkedin':
        return <iframe style={{border: 'none',height:'50vh',width:'100%'}} src="https://www.linkedin.com/in/benjamin-guendouz-533419134/" ></iframe>;
      default:
        return null;
    }
  };


  return (
    <div
      onClick={type === 'console' ? () => setChatFocus() : undefined}
      className={`window boxShadow transparent ${visibility ? 'visible' : 'closing'}`}
      style={{
        width: width ? width : '100%', // Fullscreen in expanded mode
      }}
    >
      <div className='clickMe'>Click me !</div>
      <header style={{ position: 'relative', paddingBottom: '3px', height: '3vh' }}>
        { type === 'pdf' || type === 'console' ? <div className="window-header light-gray">{windowName}</div> : windowName }
        <div className="window-header-button">
          <div className="button" onTouchStart={closeWindow} onClick={closeWindow}>
            -
          </div>
          <div className="button" onTouchStart={expandWindow} onClick={expandWindow}>
            O
          </div>
          <div className="button" onTouchStart={closeWindow} onClick={closeWindow}>X</div>
        </div>
      </header>
        <div id="window" ref={contentRef}  className="scrollable"   
          style={{
            overflow: type === 'pdf' || type === 'exp' || type === 'console' ? 'auto' : 'visible',
            overflowX: type==='console' ? 'hidden' : 'auto',
            height: windowExpanded ? '100vh' : height || 'auto',
            display: type === 'console' ? 'flex' : 'block', // default to 'block' if not 'console'
            flexDirection: type === 'console' ? 'column-reverse' : 'initial' // set only for 'console'
          }}>
          
      <div
        id="window-bg"
        style={{
          height: height ? height : 'auto',
          backgroundImage: `url(${background})`, // Use backgroundImage for image backgrounds
          backgroundSize: 'cover',               // Optional: Ensures the image covers the entire div
          backgroundPosition: 'center',          // Optional: Centers the image within the div
          position: 'fixed', // Relative to this fixed container
          zIndex:'-1'
        }}
      >
      </div>
          {renderContent()}
        </div>
    </div>
  );
};

export default Window;
