import React, { useEffect } from "react";
import { Badge, CssBaseline, Zoom } from "@mui/material";
import { Box, Grid } from "@mui/material";
import { useState, useRef } from "react";
import './styles/styles.scss';
import Draggable from "react-draggable";
import Window from "./components/Window.tsx";
import SvgLogo1 from "./components/SvgLogo.jsx";
import ReactCountryFlag from "react-country-flag";

function App() {


  const slowDivRef = useRef(null);  // Reference for the slow scrolling div
  const cursorRef = useRef(null);   // Reference for the cursor div
  const [windowVisibility, setWindowVisibility] = useState({
    pdf: false,
    exp: false,
    contact: false,
    console: true,
    video: false,
    projects: false,
    linkedin: false,
  });

  const [windowExpanded, setWindowExpanded] = useState({
    pdf: false,
    exp: false,
    contact: false,
    console: false,
    video: false,
    projects: false,
    linkedin: false,
  });
  const handleWindowClose = (windowName) => {
    setWindowVisibility(prevState => ({
      ...prevState, // Spread previous state
      [windowName]: false    // Set pdf visibility to false
    }));
  };


  const expandWindow = (windowName) => {
    setWindowExpanded(prevState => ({
      ...prevState, // Spread previous state
      [windowName]: !prevState[windowName]    // Set pdf visibility to false
    }));

  }
  const data = [
    { prompt: 'cat NOM_PRENOM.md', output: '> Benjamin Guendouz' },
    { prompt: 'cat AGE.md', output: '> 36' },
    { prompt: 'cat PROFESSION.md', output: '> Développeur d\'application web FullStack. 💻 / Chef de projet technique. 🧑‍🍳'},
    { prompt: 'cat TJM.md', output: '> 600€' },
    { prompt: 'cat MISC.md', output: '> 2 kids 👼👼' },
  ];

  const windows = [
    { id: 1, label: 'CV', name: 'pdf', visibility: windowVisibility.pdf,  content: <Window visibility={windowVisibility.pdf} closeWindow={() => handleWindowClose('pdf')} type="pdf" windowName={'📄 Curriculum Vitae.pdf'} height={'50vh'} expandWindow={() => expandWindow('pdf')} windowExpanded={windowExpanded.pdf}/> },
    { id: 2, label: 'Contact', name: 'contact', visibility: windowVisibility.contact,  content: <Window visibility={windowVisibility.contact} closeWindow={() => handleWindowClose('contact')} type="contact" windowName={'📧 Contact'} expandWindow={() => expandWindow('contact')} windowExpanded={windowExpanded.contact} /> },
    { id: 3, label: 'Experiences', name: 'exp', visibility: windowVisibility.exp, content: <Window visibility={windowVisibility.exp} closeWindow={() => handleWindowClose('exp')} type="exp" windowName={'👨‍💻 Experiences'} background={'#eee'} height={'75vh'} expandWindow={() => expandWindow('exp')} windowExpanded={windowExpanded.exp} /> },
    { id: 4, label: 'Chat', name: 'console', visibility: windowVisibility.console, content: <Window visibility={windowVisibility.console} closeWindow={() => handleWindowClose('console')} type="console" background={'static/image.png'} data={data} windowName={'💻 Ben@ONLINE-GZDV2023:~  X'} height={'50vh'} expandWindow={() => expandWindow('console')} windowExpanded={windowExpanded.console} /> },
    { id: 5, label: 'Démo', name: 'video', visibility: windowVisibility.video, content: <Window visibility={windowVisibility.video} closeWindow={() => handleWindowClose('video')} type="video" windowName={'🎞️ CV Video.mp4'} expandWindow={() => expandWindow('video')} windowExpanded={windowExpanded.video} /> },
    { id: 6, label: 'Projets', name: 'projects', visibility: windowVisibility.projects, content: <Window visibility={windowVisibility.projects} closeWindow={() => handleWindowClose('projects')} type="projects" windowName={'🦠 Projets'} expandWindow={() => expandWindow('projects')} windowExpanded={windowExpanded.projects} /> },
    // { id: 7, name: 'linkedin', visibility: windowVisibility.linkedin, content: <Window visibility={windowVisibility.linkedin} closeWindow={() => handleWindowClose('linkedin')} type="linkedin" windowName={'🥹 linkedin'} expandWindow={() => expandWindow('linkedin')} windowExpanded={windowExpanded.linkedin} /> },
    // Add more windows if needed
  ];
  
  const [load, setLoad] = useState(0);
  const [zIndices, setZIndices] = useState(windows.map(() => 0)); // Track z-index for each window
  const [highestZIndex, setHighestZIndex] = useState(1); // Track the current highest z-index
  // Function to handle bringing the clicked window to the front
  const bringToFront = (index) => {
    const newZIndices = [...zIndices];
    newZIndices[index] = highestZIndex + 1; // Increase the z-index for the clicked window
    setZIndices(newZIndices);
    setHighestZIndex(highestZIndex + 1); // Update the highest z-index
  };

  const clickLink = (index, name) => {
    
    if( windowVisibility[name])
    {
      if(zIndices[index] < highestZIndex) bringToFront(index); 
        else setWindowVisibility(prevState => ({...prevState, [name]: !windowVisibility[name]}));
    }
    else {
      if(!windowVisibility[name])
        {
          setWindowVisibility(prevState => ({...prevState, [name]: !windowVisibility[name]}));
          bringToFront(index); 
        }else{
          setWindowVisibility(prevState => ({...prevState, [name]: !windowVisibility[name]}));
        }
    }
  }

  useEffect(() => {
    const handleMouseMove = (ev) => {

      if (cursorRef.current) {
        setTimeout(() => {
        cursorRef.current.style.left = `${ev.pageX}px`;
        cursorRef.current.style.top = `${ev.pageY}px`;
        }, 200);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const int = setInterval(() => {
      setLoad(prevLoad => {
        if (prevLoad >= 100) {
          clearInterval(int);
          return 100; // Ensure it stays at 100
        }
        return prevLoad + 1;
      });
    }, 30);

    return () => clearInterval(int); // Cleanup on unmount
  }, []);

  const scale = (num, in_min, in_max, out_min, out_max) => {
    return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  };


  return (
    <>
  

      <CssBaseline />
      {/* <div className="spinningContainer" style={load >= 100 ? {right: '-3vw',position:'fixed',bottom: 0, zIndex:3} : {position:'absolute', top: "55%",left: "43%"}}>
          <SvgLogo1 />   
      </div> */}
      <div
        className="loading-text"
        style={{ opacity: scale(load, 0, 100, 1, 0) }}
      >
       {load}%
      </div>
      <Box className='container transparent loader'
        style={{ opacity: load >= 100 ? 1 : 0 }} id="top">
      <div style={{position:'absolute', right:'1vw', bottom:'1vh', fontSize:'1vw', color:'gray'}}>v1.0.1</div>
      <div className="cursor" ref={cursorRef} style={{ position: 'absolute' }}></div>
        <Grid className="main-header" container style={{display:"flex", justifyContent: "space-between"}}>
          <Grid item md="auto" className="flex-right ">
          {/* <a class="eigth before after" href="#" onClick={() => {clickLink(index, window.name)}}>{window.name}</a> */}
          <a className="glassIco" target="_blank" href="https://www.linkedin.com/in/benjamin-guendouz-533419134/"><i className="fab fa-linkedin-in"></i></a>


          {/* <a className="glassIco" href="https://github.com/culdz"><i className="fab fa-github"></i></a> */}
          </Grid>
          <Grid item md="auto" className="flex-right " >
          <ReactCountryFlag
                countryCode="GB"
                svg
                style={{
                    width: '2vw',
                    height: '2vh',
                }}
                title="ENG"
            />          
            <ReactCountryFlag
            countryCode="FR"
            svg
            style={{
                width: '2vw',
                height: '2vh',
            }}
            title="FR"
        />
          </Grid>

        </Grid>
        
        <Grid 
           ref={slowDivRef} className="main-banner parallax-slow" container >
          
        <Grid item xs={12} >
          <Grid container >
            <Grid item xs={12} sx={{height:'100%'}}>
              <Grid container sx={{height:'100%'}}>
                <Grid item md={5} xs={12} sx={{flexDirection:'column'}} className="flex-container">
                  <div className="intro">
                      <div className="title">

                        <div class="line">Développeur</div>
                        <div class="line">Fullstack</div>
                      </div>
                    <div className="intro_text">
                      <span>Fort de plus de 12 ans d'expérience dans le développement de solutions web, je gère l'ensemble du processus, de l'architecture et la modélisation de bases de données au développement d'API et à la mise en production. Je m'occupe de tous les aspects du développement pour garantir des solutions performantes et adaptées.</span>
                    </div>
                  </div>
                  
                  <div className="link-1">
                      {windows.map((window, index) => (
                          window.name != 'linkedin' && (
                            <a href="#" onClick={() => {clickLink(index, window.name)}}>{window.label}</a>
                          )
                      )    
                      
                      )}
                    </div>
                
                </Grid>


                <Grid item md={7} xs={12} className="windowbox flex-container">
                    
                  <div className="Draggable100w">
                    {windows.map((window, index) => (
                      <Draggable
                      defaultClassName="Draggable100w2"
                      key={window.name}
                      onMouseDown={() => bringToFront(index)}
                      // Only lock position when window is expanded, else leave it draggable
                      defaultPosition={{ x: 0, y: 0 }} // Initial position
                    >
                      <div
                        style={{
                          zIndex: windowExpanded[window.name] ? 9999 : zIndices[index],
                          transition: 'opacity 0.5s ease',
                          opacity: window.visibility ? 1 : 0,
                          pointerEvents: window.visibility ? 'auto' : 'none',
                          position: windowExpanded[window.name] ? 'fixed' : 'absolute', // Fixed if expanded
                          top: windowExpanded[window.name] ? 0 : 'auto',
                          left: windowExpanded[window.name] ? 0 : 'auto',
                          width: windowExpanded[window.name] ? '100vw' : '100%', // Fullscreen in expanded mode
                          height: windowExpanded[window.name] ? '100vh' : '100%',
                          }}
                      >
                        {window.content}
                      </div>
                    </Draggable>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
        
        <Grid className="main-footer" container>
          <Grid item xs={12}  >

          </Grid>
        </Grid>
      </Box>
  </>);
}

export default App;
